<template>
  <div class="report-forms">
    <nav
      class="navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-0 shadow"
    >
      <a
        class="navbar-brand col-md-3 col-lg-2 mr-0 px-3"
        style="font-size: 1rem"
        @click="goPage('Main')"
        >中山市恒丰利织造有限公司</a
      >
      <button
        class="navbar-toggler position-absolute d-md-none collapsed"
        type="button"
        data-toggle="collapse"
        data-target="#sidebarMenu"
        aria-controls="sidebarMenu"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <ul class="navbar-nav px-3">
        <li class="nav-item text-nowrap" v-if="isLogin">
          <span style="color: aliceblue">{{ cust_name }}</span>
        </li>
      </ul>
      <ul class="nav px-3 navbar-right" style="line-height: 43px">
        <li class="nav-item text-nowrap" v-if="isLogin">
          <a style="color: aliceblue; margin-right: 3rem"
            >欢迎，{{ username }}</a
          >
        </li>
        <li class="nav-item text-nowrap" v-if="isLogin">
          <a class="logout" @click="logout">退出
            <i class="fa fa-sign-out" aria-hidden="true"></i>
          </a>

        </li>
      </ul>
    </nav>
    <div class="container-fluid">
      <div class="row">
        <nav
          id="sidebarMenu"
          class="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse"
        >
          <div class="sidebar-sticky pt-3">
            <ul class="nav flex-column">
              <li class="nav-item">
                <a class="nav-link" @click="goPage('AdminHome')">
                  <i class="fa fa-home fa-lg" aria-hidden="true" style="margin-right: 16px;"></i>
                  首页
                </a>
              </li>

              <li class="nav-item">
                <a class="nav-link" @click="goPage('OrderInfo')">
                  <i class="fa fa-pencil-square-o fa-lg" aria-hidden="true"></i>创建订单
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" @click="goPage('OrderQuery')">
                  <i class="fa fa-search fa-lg" aria-hidden="true"></i>订单查询
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" @click="goPage('Customer')">
                  <i class="fa fa-user fa-lg" aria-hidden="true"></i>客户
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link active"
                  ><i class="fa fa-list" aria-hidden="true" style="margin-right: 16px;"></i> 报表
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" @click="goPage('SystemSet')">
                  <i class="fa fa-cog fa-lg" aria-hidden="true"></i>系统设置
                </a>
              </li>
            </ul>
          </div>
        </nav>
        <main
          role="main"
          class="col-md-9 ml-sm-auto col-lg-10 px-md-4"
          id="main"
        >
        <div class="logo">恒丰利</div>
        </main>
      </div>
    </div>
    <footer class="py-5 bg-dark fixed-bottom">
      <div class="container">
        <p class="m-0 text-center text-white">
          Copyright &copy; 恒丰利织造有限公司 2021<a class="icp" @click="goBean">粤ICP备17115312号</a>
        </p>
      </div>
    </footer>
  </div>
</template>

<script>
import "../assets/css/reportforms.less";
import "../assets/css/dashboard.less";

export default {
  data() {
    return {
      username: "",
      cust_id: "",
      cust_name: "",
      isLogin: false,
      url: "http://beian.miit.gov.cn/"
    };
  },

  created() {
    this.getUserInfo();
    if (sessionStorage.getItem("user")) {
      this.isLogin = true;
    }
  },

  methods: {
    // 获取用户信息
    getUserInfo() {
      this.username = sessionStorage.getItem("user");
      this.cust_id = sessionStorage.getItem("cust_id");
      this.cust_name = sessionStorage.getItem("cust_name");
    },

    goPage(name) {
      this.$router.push({ name });
    },

    // 退出登录
    logout() {
      sessionStorage.clear();
      this.$router.push({ name: "Login" });
    },
    goBean(){
      var r = confirm("您将要访问：http://beian.miit.gov.cn/，是否继续？");
      if(r==true){
        window.location.href = this.url;
      }
    }
  },
};
</script>

<style lang="less" scoped></style>
